
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const Header1=()=>{
   

   
   
    return(


<div>
          
<Navbar fixed="top"  bg="light" expand="lg">
          <Container>
              <Navbar.Brand href="/"><strong>Diamond</strong></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            
          
          
          
                  
            <Nav.Link href="/">Signin</Nav.Link>
           

            </Nav>
            </Navbar.Collapse>
                
                
            </Container>
    </Navbar>

    </div>





    )

   
    
    }

export default Header1;