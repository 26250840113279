import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';

import { AuthProvider } from "react-auth-kit"
import PWAPrompt from 'react-ios-pwa-prompt'

ReactDOM.render(



  <AuthProvider
  authType={"cookie"}
  authName={"_auth"}
  cookieDomain={window.location.hostname}
  cookieSecure={true}
>
  
  <BrowserRouter>
    <App /><PWAPrompt copyTitle="Diamond" />
  </BrowserRouter>
  
  </AuthProvider>
  
  ,
  document.getElementById("root")
  
);
