import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';

import Header from "../Header";
import Nav from 'react-bootstrap/Nav';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';
import {useAuthUser} from 'react-auth-kit'
const Income = () => {
 
  const auth = useAuthUser();  
 
  
  useEffect( () => {
    
   
      allusers();
    window.scrollTo(0, 0);
   
    allincome();
  
    
 
  }, []); 

 
  const allusers = async () => {
    
  
    try {
  
      
      axios.get(`https://diamond.future-swift.com/api/user.php`)
      .then(res => {
       
        setUser(res.data.productlist.productdata);
      })
    } catch (error) { throw error;}    
  
  }



  const handleSelect = (eventKey) =>{
    localStorage.setItem('user',eventKey);
    setIncome([]);
    
    allincome();
   
   
  } 
  
  const handleSelect1 = (eventKey) =>{
    localStorage.setItem('week',eventKey);
   setIncome([]);
    allincome();
  } 
  
  const [user, setUser] = useState([]);
  const [income, setIncome] = useState([]);
  
const [loading, setLoading] = useState(false);

 
  const allincome = async () => {
    setLoading(true);
  
    try {
     
      var show = localStorage.getItem('user'); 
      var week = localStorage.getItem('week');  
      const username =  auth().username;
      const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/income.php?key=`+key+`&username=`+username+'&show='+show+'&week='+week)
      .then(res => {
        
        setIncome(res.data.productlist.productdata);
        totalincome();
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }  
  }


  
  
  
  const [tincome, setTincome] = useState([]);
  
  const totalincome = async () => {
    
  
    try {
     
      var show = localStorage.getItem('user'); 
      var week = localStorage.getItem('week');  
      const username =  auth().username;
      const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/totalincome.php?key=`+key+`&username=`+username+'&show='+show+'&week='+week)
      .then(res => {
        setTincome([]);
        setTincome(res.data.productlist.productdata[0]);
      })
   
    } catch (error) { throw error;}    
  
  }



  const [enddate, setendDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date()); 
  const incomeft = async (date,date1) => {
    
  
    try {
      var show = localStorage.getItem('user'); 
      const username =  auth().username;
      const key = auth().key;
      axios.get(`https://diamond.future-swift.com/api/filterincome.php?key=`+key+`&username=`+username+'&date='+date+'&date1='+date1+'&show='+show)
      .then(res => {
        
        setIncome(res.data.productlist.productdata);
        totalincome_d(date,date1);
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }  
  }
  
  
  
  const filt = () =>{
    setLoading(true);
    setIncome([]);
    setTincome([]);
    incomeft(format(startDate, 'yyyy-MM-dd'),format(enddate, 'yyyy-MM-dd'));
    
  } 




  const totalincome_d = async (date,date1) => {
    
  
    try {
      var show = localStorage.getItem('user'); 
      const username =  auth().username;
      const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/totalincome_d.php?key=`+key+`&username=`+username+'&date='+date+'&date1='+date1+'&show='+show)
      .then(res => {
        setTincome([]);
        setTincome(res.data.productlist.productdata[0]);
      })
   
    } catch (error) { throw error;}    
  
  }


  var nf = new Intl.NumberFormat();
 


  const tableRef = useRef(null);
  
  var i=0;
  return (
    
    <div>
    <Header/>
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>
        <h1>الايرادات</h1>
    <h2>:عرض ايرادات</h2>
    <Nav   fill variant="tabs"  defaultActiveKey={localStorage.getItem('user')} onSelect={handleSelect}>
    <Nav.Item >
          <Nav.Link  eventKey="0" >الكل</Nav.Link>
        </Nav.Item>
    {user.map((user,index)=>( 
      
        <Nav.Item>
          <Nav.Link eventKey={user.id}>{user.name}</Nav.Link>
        </Nav.Item>
        
        
        ))}
       
      </Nav>

       <br />
    
      <br /> <br />
      <label htmlFor="_date">من</label>
    <DatePicker selected={startDate} onChange={(date) =>   
setStartDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

    <label htmlFor="_date">الى</label>
    <DatePicker selected={enddate} onChange={(date) =>   
setendDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

  <button onClick={filt}>بحث</button>

<br /> <br />
  
    
    
    <div className='container1'>
    <DownloadTableExcel 
                    filename="income"
                    sheet="الايرادات"
                    currentTableRef={tableRef.current}
                >

                   <button id='excel'> Export excel </button>

                </DownloadTableExcel>
                <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef.current}
      />
    <table class="table" ref={tableRef}>
    <thead>
    <tr>
    <th scope="col">التاريخ</th>
    <th scope="col">المستخدم</th>
    <th scope="col">الوارد</th>
   
    <th scope="col">لحساب</th>
    <th scope="col">رقم الحساب</th>
    <th scope="col">#</th>
    
    
   
    
       
   
   
  
        </tr>
        </thead>
  <tbody>
    {income.map((item,index)=>(
      

        <tr>
        <td>{item.date}</td> 
        <td>{item.admin_name}</td> 
        <td>{ nf.format(item.paid)} </td>
   
        <td>{item.client_name}</td>
        <td>{item.client_id}</td>
       
        <th scope="row">{i+=1}</th>	
        
       </tr>
     
              
  ))}
  </tbody>
  
 <tfoot>
  
  <tr>
  <th  scope="row">اجمالي الايرادات</th>
  <td colSpan={9}><b>{ nf.format(Number(tincome.t_paid))}</b></td>
  </tr>
  </tfoot>
  </table>
  </div>
     
  </div>
 )}

    </div>
  )
  }
  

export default Income;

