import React,{useState,useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {useAuthUser} from 'react-auth-kit'
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';




const Editincome = (props) => {

  const navigate = useNavigate();
  const auth = useAuthUser();  
 
useEffect( () => {

 

  
    allclient();
  

}, []); 



const [client, setClient] = useState([]);


const allclient = async () => {
  

  try {
    const username =  auth().username;
    const key = auth().key;
    
    axios.get(`https://diamond.future-swift.com/api/client.php?key=`+key+'&username='+username)
    .then(res => {
     
      setClient(res.data.productlist.productdata);
    })
  } catch (error) { throw error;}    

}



 
  const [saleinfo, setSaleinfo] = useState({
    client: props.list.client,
    note: props.list.note,
    receipt:props.list.receipt,
    
    paid: props.list.paid,
    
  });
  const onChangeValue = (e) => {
    setSaleinfo({
      ...saleinfo,
      [e.target.name]:e.target.value
    });
  } 








  const [date, setDate] = useState(new Date(props.list.date)); 

  // Inserting a new user into the Database.
  const updatesale = async(event) => {
    try {
      event.preventDefault();
      event.persist();
    
      var formData = new FormData();

      formData.append("pay_id",props.list.pay_id); 
      formData.append("client",saleinfo.client); 
      formData.append("user",saleinfo.user); 
      formData.append("paid",saleinfo.paid);  
      formData.append("date",format(date, 'yyyy-MM-dd')); 
      formData.append("note",saleinfo.note); 
      formData.append("receipt",saleinfo.receipt); 
      formData.append("username",auth().username); 
      formData.append("key",auth().key); 

      axios.post(`https://diamond.future-swift.com/api/editincome.php`, formData )
      .then(res => {
        console.log(res.data);
        navigate(`/myincome`);
        return;
       })
    } catch (error) { throw error;}    
  };


 



  







return (
  <dev>  
 
 <form className="insertForm" onSubmit={updatesale}>
    <h1> تعديل الايراد </h1>

    
    

  <label htmlFor="_client">لحساب</label> 
  <br /> 
    <select id='_client' name='client' onChange={onChangeValue} required>
    
        {client.map(option => (
          
            option.value === saleinfo.client ?
        <option selected key={option.value} value={option.value}>
            {option.label}
          </option>
          :<option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      
<br /> <br />
 
 
    
    <label htmlFor="_paid">الوارد</label>
    <input
      type="number"
      id="_paid"
      name="paid"
      min={0}
      value={saleinfo.paid}
      onChange={onChangeValue}
      placeholder="Enter paid"
      autoComplete="off"
      required
    />
    <br /> <br />

    <label htmlFor="_date">  التاريخ </label>
    <DatePicker  selected={date} onChange={(date) =>   
setDate(date)}
dateFormat="yyyy-MM-dd" 

required

/> 
<br /> <br />
<div dir='rtl' className="flex">
   <label htmlFor="_receipt"> السند</label>
  <input
  style={{width:'30%'}}
      type=""
      id="_receipt"
      name="receipt"
      value={saleinfo.receipt}
      onChange={onChangeValue}
      placeholder="Receipt no."
      autoComplete="off"

    />
   
   <label htmlFor="_note"> ملاحظة</label>
  <textarea
  style={{width:'60%'}}
      type=""
      id="_note"
      name="note"
    value={saleinfo.note}
      onChange={onChangeValue}
      placeholder="Optional"
      autoComplete="off"
    
    />

    </div>
    <br /> 
    <input type="submit" value="حفظ" />
  </form>
  </dev>
);
};

export default Editincome;