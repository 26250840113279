import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';

import Header from "../Header";
import Nav from 'react-bootstrap/Nav';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';
import {useAuthUser} from 'react-auth-kit'

const Clientsreport = () => {
 
  const auth = useAuthUser();  

  
  useEffect( () => {
   
    
  
    allclients();

    allusers();
  
  }, []); 
  

 
  const allusers = async () => {
    
  
    try {
  
      
      axios.get(`https://diamond.future-swift.com/api/user.php`)
      .then(res => {
       
        setUser(res.data.productlist.productdata);
      })
    } catch (error) { throw error;}    
  
  }



  const handleSelect = (eventKey) =>{
    localStorage.setItem('user',eventKey);
    setClients([]);
    allclients();
   
  } 
  
  const handleSelect1 = (eventKey) =>{
    localStorage.setItem('week',eventKey);
    setClients([]);
    allclients();
   
  } 
  
  const [user, setUser] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const allclients = async () => {
    setLoading(true);
  
    try {
     
      var show = localStorage.getItem('user'); 
      var week = localStorage.getItem('week');  
      const username =  auth().username;
      const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/clientsreport.php?key=`+key+`&username=`+username+'&show='+show+'&week='+week)
      .then(res => {
        
        setClients(res.data.productlist.productdata);
        totalallclients();
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }  
  }
  
  const [totalall, setTotalall] = useState([]);

  const totalallclients = async () => {
    
  
    try {
     
      var show = localStorage.getItem('user'); 
      var week = localStorage.getItem('week');  
      const username =  auth().username;
      const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/totalallclients.php?key=`+key+`&username=`+username+'&show='+show+'&week='+week)
      .then(res => {
        setTotalall([]);
        setTotalall(res.data.productlist.productdata[0]);
      })
   
    } catch (error) { throw error;}    
  
  }

  
  const [enddate, setendDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date()); 

  const clientsft = async (date,date1) => {
    setLoading(true);
    try {
      var show = localStorage.getItem('user'); 
      const username =  auth().username;
      const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/filterallclient.php?key=`+key+`&username=`+username+'&date='+date+'&date1='+date1+'&show='+show)
      .then(res => {
        
        setClients(res.data.productlist.productdata);
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }  
  }
  
  
  
  const filt = () =>{
    setClients([]);
    clientsft(format(startDate, 'yyyy-MM-dd'),format(enddate, 'yyyy-MM-dd'));
    totalallclients_d(format(startDate, 'yyyy-MM-dd'),format(enddate, 'yyyy-MM-dd'));
  } 
  



  const totalallclients_d = async (date,date1) => {
    
    try {
      var show = localStorage.getItem('user'); 
      const username =  auth().username;
      const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/totalallclients_d.php?key=`+key+`&username=`+username+'&date='+date+'&date1='+date1+'&show='+show)
      .then(res => {
        setTotalall([]);
        setTotalall(res.data.productlist.productdata[0]);
      })
   
    } catch (error) { throw error;}    
  
  }


  const tableRef = useRef(null);
  var nf = new Intl.NumberFormat();


  var i=0;
  return (
    
    <div>
    <Header/>
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>
    <h2>:تقرير ل</h2>
    <Nav   fill variant="tabs"  defaultActiveKey={localStorage.getItem('user')} onSelect={handleSelect}>
    <Nav.Item >
          <Nav.Link  eventKey="0" >الكل</Nav.Link>
        </Nav.Item>
    {user.map((user,index)=>( 
      
        <Nav.Item>
          <Nav.Link eventKey={user.id}>{user.name}</Nav.Link>
        </Nav.Item>
        
        
        ))}
       
      </Nav>

       <br />
     
   
      <label htmlFor="_date">من</label>
    <DatePicker selected={startDate} onChange={(date) =>   
setStartDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

    <label htmlFor="_date">الى</label>
    <DatePicker selected={enddate} onChange={(date) =>   
setendDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

  <button onClick={filt}>بحث</button>

<br /> <br />
    <h1>تقرير تفصيلي للحسابات/للعملاء</h1>
    
    
  
    <div className='container1'>
    <DownloadTableExcel 
                    filename="allclient"
                    sheet="العملاء"
                    currentTableRef={tableRef.current}
                >

                   <button id='excel'> Export excel </button>

                </DownloadTableExcel>
                <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef.current}
      />
    <table class="table" ref={tableRef}>
    <thead>
    <tr>
    <th scope="col">التاريخ</th>
    <th scope="col">المستخدم</th>
    <th scope="col">المتبقي</th>
    <th scope="col">الوارد</th>
   
    <th scope="col">طريقة الدفع</th>
    <th scope="col">الأجمالي</th>
    <th scope="col">السعر</th> 
    <th scope="col">العدد</th>
    <th scope="col">الصنف</th>
    <th scope="col">اسم العميل</th>
    <th scope="col">رقم العميل</th>
    <th scope="col">#</th>
    
    
   
    
       
   
   
  
        </tr>
        </thead>
  <tbody>
    {clients.map((item,index)=>(
      
      
  Number(item.total) === 0 ?  
        <tr>
        <td>{item.date}</td> 
        <td>{item.admin_name}</td> 
        <td></td>
        <td>{item.paid} </td>
    
        <td>دفع لاجل</td>
        <td colSpan={4} >دفع من مبلغ متبقي عليه</td>
        
        <td>{item.client_name}</td>
        <td>{item.client_id}</td>
       
        <th scope="row">{i+=1}</th>	
        
       </tr>
      :
     <tr>
        <td>{item.date}</td> 
        <td>{item.admin_name}</td> 
        <td>{nf.format(Number(item.total)-Number(item.paid))} </td>
        <td>{nf.format(item.paid)} </td>
  
        <td>{Number(item.status) === 0 ? "اجل" 
          : "نقد"}</td>
        <td>{nf.format(item.total)} </td>
        <td>{nf.format(item.price)} </td>
        <td>{item.amount}</td>
        <td>{item.product}</td> 
        <td>{item.client_name}</td>
        <td>{item.client_id}</td>
        <th scope="row">{i+=1}</th>	
     	</tr>
       	
     
      
       
              
  ))}
  </tbody>
 

<tfoot>
<tr>
<th  colSpan={4} scope="row">اجمالي المبيعات</th>
<th colSpan={4}  scope="row">اجمالي الوارد</th>
<th colSpan={4} scope="row">اجمالي الاجل</th>
</tr>


<tr>

<td colSpan={4}><b>{nf.format(Number(totalall.t_total))}</b></td>
<td colSpan={4}><b>{nf.format(Number(totalall.t_paid1))}</b></td>
<td colSpan={4}><b>{nf.format(Number(totalall.t_total)-Number(totalall.t_paid))}</b></td>
</tr>
  
  </tfoot>
  </table>
  </div>
     
  </div>
 )}

    </div>
  )
  }
  

export default Clientsreport ;









