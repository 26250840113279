import React,{useState,useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {useAuthUser} from 'react-auth-kit'

import DatePicker from 'react-datepicker';
import { format } from 'date-fns';



const Editincome = (props) => {

  const navigate = useNavigate();

  const auth = useAuthUser();  

useEffect( () => {
 
    allemp();
}, []); 



const [emp, setEmp] = useState([]);


const allemp = async () => {
  

    try {
      const username =  auth().username;
      const key = auth().key;
      
      axios.get(`https://diamond.future-swift.com/api/employee.php?key=`+key+'&username='+username)
      .then(res => {
       
        setEmp(res.data.productlist.productdata);
      })
    } catch (error) { throw error;}    
  
  }
  



 
  const [saleinfo, setSaleinfo] = useState({
    emp: props.list.emp,
    total: props.list.total,
    reason: props.list.reason,
    
  });
  const onChangeValue = (e) => {
    setSaleinfo({
      ...saleinfo,
      [e.target.name]:e.target.value
    });
  } 

  const [date, setDate] = useState(new Date(props.list.date)); 
  // Inserting a new user into the Database.
  const updatesale = async(event) => {
    try {
      event.preventDefault();
      event.persist();
    
      var formData = new FormData();
      formData.append("out_id",props.list.out_id); 
      formData.append("emp",saleinfo.emp); 
      formData.append("total",saleinfo.total);  
      formData.append("reason",saleinfo.reason);  
      formData.append("date",format(date, 'yyyy-MM-dd')); 
      formData.append("username",auth().username); 
      formData.append("key",auth().key); 

      axios.post(`https://diamond.future-swift.com/api/editoutcome.php`, formData )
      .then(res => {
        console.log(res.data);
        navigate(`/myoutcome`);
        return;
       })
    } catch (error) { throw error;}    
  };


 



  







return (
  <dev>  
 
 <form className="insertForm" onSubmit={updatesale}>
    <h1> تعديل الصرف </h1>

    
    
    <label htmlFor="_emp">على حساب</label> 
    <br/>
    {emp.length > 0 ? <select id='_emp' name='emp'  onChange={onChangeValue} required>
    {emp.map(option => (
        option.value === saleinfo.emp ?
        <option selected key={option.value} value={option.value}>
            {option.label}
          </option>
          :<option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      :<select id='_emp' className='select' name='employee' defaultValue='o' onChange={onChangeValue} required>
    <option value='' selected> لا يوجد موظف </option>
    
      </select>
}

   
    <br /> <br />
    
    <label htmlFor="_total">المبلغ</label>
    <input
      type="number"
      id="_total"
      name="total"
      min={0}
      value={saleinfo.total}
      onChange={onChangeValue}
      placeholder="Enter total"
      autoComplete="off"
      required
    />
    <br /> <br />
    <label htmlFor="_reason">الصرف لأجل</label>
    <input
      type="text"
      id="_reason"
      name="reason"
      value={saleinfo.reason}
      onChange={onChangeValue}
      placeholder="Enter Reason"
      autoComplete="off"
     
    />
     <br /> <br />
     <label htmlFor="_date">  التاريخ </label>
    <DatePicker  selected={date} onChange={(date) =>   
setDate(date)}
dateFormat="yyyy-MM-dd" 

required

/> 
    <input type="submit" value="حفظ" />
  </form>
  </dev>
);
};

export default Editincome;