import React, {useEffect,useState} from 'react'
import axios from 'axios';

import Header from "../Header";
import Myoutcome from "./Myoutcome";
import {useAuthUser} from 'react-auth-kit'
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
const Addoutcome = () => {
  const auth = useAuthUser();  


const [emp, setEmp] = useState({});
const [out, setOutput] = useState({
  employee: '',
  total:'',
  reason:'',
});



useEffect( () => {

 


    allemp();


 
 
}, []); 


const allemp = async () => {
  

  try {
    const username =  auth().username;
    const key = auth().key;
    axios.get(`https://diamond.future-swift.com/api/employee.php?key=`+key+'&username='+username)
    .then(res => {
     
      setEmp(res.data.productlist.productdata);
    })
  } catch (error) { throw error;}    

}





const onChangeValue = (e) => {
  setOutput({
    ...out,
    [e.target.name]:e.target.value
  });

  
} 


const [date, setDate] = useState(new Date()); 
const addoutput = async(event) => {
   
   
  try {
    event.preventDefault();
    event.persist();

   const username =  auth().username;
    const key = auth().key;
  
    var formData = new FormData();

    formData.append("username",username); 
    formData.append("key",key); 
    formData.append("employee",out.employee); 
    formData.append("total",out.total); 
    formData.append("date",format(date, 'yyyy-MM-dd')); 
    formData.append("reason",out.reason); 
    
   
    axios.post(`https://diamond.future-swift.com/api/addoutcome.php`, formData)
    .then(res => {
      console.log(res.data);
      window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};

return (
  
  <dev>
    <Header/>
   
  <form className="insertForm" onSubmit={addoutput}>
    <h1> اضافة صرف جديد </h1>

    
    <label htmlFor="_emp">على حساب</label> 
    <br></br>
    {emp.length > 0 ?  <select id='_emp' name='employee' onChange={onChangeValue} required>
    <option value='' selected> Choose... </option>
    {emp.map(option => (
          
          <option  key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    :<select id='_emp' className='select' name='employee' defaultValue='o' onChange={onChangeValue} required>
    <option value='' selected> لا يوجد حساب </option>
    
      </select>
}

    <br /> <br />
  
    <label htmlFor="_total">المبلغ</label>
    <input
      type="number"
      id="_total"
      name="total"
      min={0}
      onChange={onChangeValue}
      placeholder="Enter total"
      autoComplete="off"
      required
    />
    <br /> <br />
    <label htmlFor="_reason">الصرف لأجل</label>
    <input
      type="text"
      id="_reason"
      name="reason"
      onChange={onChangeValue}
      placeholder="Enter Reason"
      autoComplete="off"
     
    />


<label htmlFor="_date">  التاريخ </label>
    <DatePicker  selected={date} onChange={(date) =>   
setDate(date)}
dateFormat="yyyy-MM-dd" 

required

/> 




     <br /> <br />
    <input type="submit" value="حفظ" />
  </form>

<Myoutcome/>

  </dev>
)
}


export default Addoutcome;









