import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {  useParams } from "react-router-dom";
import Editclient from "./Editclient";
import {useAuthUser} from 'react-auth-kit'
const Edit3 = () => {
  let params = useParams();
  //let navigate = useNavigate();
  const auth = useAuthUser();  
  useEffect( () => {
    window.scrollTo(0, 0);
    editsalelist(params.ids);
  }, []); 

//how to create a state in react? 
  
  const [sale, setSale] = useState([]);
  const [isloadsale, setloadsale] = useState(false);
  const editsalelist = async (ids) => {
    try {


      var formData = new FormData();
      
      formData.append("username",auth().username); 
      formData.append("key",auth().key); 
      formData.append("client_id",ids); 

      axios.post(`https://diamond.future-swift.com/api/getclient.php`, formData )
      .then(res => {
        console.log(res.data.productlist.productdata)
        setSale(res.data.productlist.productdata[0]);
        setloadsale(true);
      })
    } catch (error) { throw error;}    
  }

  return (
    <div>
    {isloadsale && 
      <Editclient list={sale} />
    }
    </div>
  )
}

export default Edit3