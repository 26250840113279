import React,{useEffect, useState} from 'react';
import axios from 'axios';

import Header from "../Header";
import Mysales from "./Mysales";
import {useAuthUser} from 'react-auth-kit'
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';

const Addsale = () => {


  const auth = useAuthUser();  
  const [loading, setLoading] = useState(false);
  useEffect( () => {
 
   
  
   
    allclient();
   
     
}, []); 




const [client, setClient] = useState([]);
const [check,setCheck]= useState(false);


  const [sale, setSale] = useState({
    client: '',
    product: '',
    price: '',
    count: '',
    status: '',
    paid:'',
    note: '',
    receipt:'',
  });


  const onChangeValue = (e) => {
    setSale({
      ...sale,
      [e.target.name]:e.target.value
    });


  } 


  const onChangeValue1 = (e) => {
    setSale({
      ...sale,
      [e.target.name]:e.target.value
    });
  
    const value = e.target.value
   

    if(value==='0') {
      setCheck(true);
     }else{
      setCheck(false);
     } 

  } 

  const handdle_change = (id) => {
    setSale({
      ...sale,
      ['client']:id
    }); 
  
  
  }
  const allclient = async () => {
  

    try {
      const username =  auth().username;
      const key = auth().key;
      
      axios.get(`https://diamond.future-swift.com/api/client.php?key=`+key+'&username='+username)
      .then(res => {
       
        setClient(res.data.productlist.productdata);
      })
    } catch (error) { throw error;}    
  
  }




  const [date, setDate] = useState(new Date()); 

  // Inserting a new product into the Database.
  const addproduct = async(event) => {
    setLoading(true);
   
    try {
      event.preventDefault();
      event.persist();

      var total = Number(sale.price)*Number(sale.count);
      const username =  auth().username;
    const key = auth().key;
    
  
      var formData = new FormData();
      
      formData.append("client_id",sale.client); 
      formData.append("product",sale.product);
      formData.append("price",sale.price); 
      formData.append("count",sale.count); 
      formData.append("status",sale.status);  
      formData.append("total",total); 
      formData.append("paid",sale.paid); 
      formData.append("note",sale.note); 
      formData.append("receipt",sale.receipt); 
      formData.append("date",format(date, 'yyyy-MM-dd')); 
      formData.append("username",username); 
      formData.append("key",key); 
      
      axios.post(`https://diamond.future-swift.com/api/addsale.php`, formData)
      .then(res => {
        console.log(res.data);
        window.location.reload(false);
       
       })
    } catch (error) { throw error;}    
    finally{

      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
   
  };



const options =[
  {value:"", label:"Choose..."},
  {value:"1", label:"نقد"},
  {value:"0", label:"اجل"},

];


  


return (


  <dev>
    <Header/>
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>
  <form className="insertF" onSubmit={addproduct}>
    <h1> اضافة بيع جديد </h1>

    
    
    <dev className="flex">
  


    
    
<DatalistInput
dir='rtl'
style={{color:'#000', }}
placeholder="العميل"
autoSave='off'
onSelect={(item) => handdle_change(item.id)}
items={  client.map(option => (

  { id: option.value, value:option.label }
  
  ))

}
/>
      

<label htmlFor="_client">حساب العميل</label> 
    
   
   
    </dev>
    <dev className="flex">
  
    <input
      type="number"
      id="_count"
      name="count"
      min={1}
      onChange={onChangeValue}
      placeholder="Enter Count"
      autoComplete="off"
      required
    />
    <label htmlFor="_count">العدد</label>


    <input
      type="number"
      id="_price"
      name="price"
      onChange={onChangeValue}
      placeholder="Enter Price"
      autoComplete="off"
      required
    />
    <label htmlFor="_price">السعر</label>





</dev>
    <div className="flex">
    
    <select id='_status' name='status' defaultValue onChange={onChangeValue1} required>


       { options.map(option => (
          <option  key={option.value} value={option.value} >
            {option.label}
          </option>
        ))}
        </select>
 
     <label htmlFor="_status">عملية الدفع</label>  
<input
readOnly
      type="text"
      id="_total"
      name="total"
      value={Number(sale.price)*Number(sale.count)}
      onChange={onChangeValue}
      placeholder="sTotal"
      autoComplete="off"
      required
    />
    <label htmlFor="_total">الاجمالي</label>

      </div>
      <div className="flex">
 
{check  ? (<div className="flex">
  <input
      type="number"
      id="_paid"
      name="paid"
      min={0}
      onChange={onChangeValue}
      placeholder="Enter paid"
      autoComplete="off"
      required
    />
    <label htmlFor="_paid">الوارد</label>
    </div>
) :
    ""
}
  
    



  </div>
  <div className="flex">
    
    <DatePicker  selected={date} onChange={(date) =>   
setDate(date)}
dateFormat="yyyy-MM-dd" /> 
" "
<label htmlFor="_date">  التاريخ </label>
   </div>

   <div dir='rtl' className="flex">
   <label htmlFor="_receipt"> السند</label>
  <input
  style={{width:'30%'}}
      type=""
      id="_receipt"
      name="receipt"
      onChange={onChangeValue}
      placeholder="Receipt no."
      autoComplete="off"

    />
   
   <label htmlFor="_note"> ملاحظة</label>
  <textarea
  style={{width:'60%'}}
      type=""
      id="_note"
      name="note"
 
      onChange={onChangeValue}
      placeholder="Optional"
      autoComplete="off"

    />

    </div>
   <br></br>
 
    <input type="submit" value="حفظ" />
  </form>

  <Mysales/>
  </div>
 )}
  </dev>
);
};

export default Addsale;




