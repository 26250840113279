import React,{useEffect, useState} from 'react';
import axios from 'axios';

import Header from "../Header";
import { format } from 'date-fns';
import {useAuthUser} from 'react-auth-kit'
import DatePicker from 'react-datepicker';

const Iceemp = () => {


  const auth = useAuthUser();  
  const [loading, setLoading] = useState(false);
  useEffect( () => {
 
    allproduct();
     
}, []); 





  const [sale, setSale] = useState({
    total: '',
    count: '',
   
  });




  const [isproduct, setproduct] = useState([]);

  const allproduct = async () => {
    
    setLoading(true);
    try {
      const username =  auth().username;
      const key = auth().key;
      axios.get(`https://diamond.future-swift.com/api/myiceemp.php?key=`+key+`&username=`+username)
      .then(res => {
        
        setproduct(res.data.productlist.productdata);
      })
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }
  }


  const onChangeValue = (e) => {
    setSale({
      ...sale,
      [e.target.name]:e.target.value
    });


  } 



  const [date, setDate] = useState(new Date()); 

  // Inserting a new product into the Database.
  const add = async(event) => {
    setLoading(true);
   
    try {
      event.preventDefault();
      event.persist();

    
      const username =  auth().username;
    const key = auth().key;
    
    
      var formData = new FormData();
      
      formData.append("price",sale.price); 
      formData.append("count",sale.count); 
      formData.append("date",format(date, 'yyyy-MM-dd')); 
      formData.append("username",username); 
      formData.append("key",key); 
      
      axios.post(`https://diamond.future-swift.com/api/add.php`, formData)
      .then(res => {
        window.location.reload(false);
       
       })
    } catch (error) { throw error;}    
    finally{

      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
   
  };





const deleteConfirm = (id) => {
    if (window.confirm(" هل أنت متأكد بأنك تريد حذف المبيع رقم"+id+" ؟ ")) {
      delete_1(id);
    }
  };
  const delete_1 = async (id) => {
    try {
  
      var formData = new FormData();
      
      formData.append("username",auth().username); 
      formData.append("key",auth().key); 
      formData.append("o_id",id); 
  
      axios.post(`https://diamond.future-swift.com/api/delete.php`,formData )
      .then(res => {
        setproduct([]);
        allproduct();
        return;
       })
    } catch (error) { throw error;}    
  }
  var nf = new Intl.NumberFormat();
  
var i=0;

var c=0;
return (


  <div>
    <Header/>
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>
  <form className="insertF" onSubmit={add}>
    <h1> اضافة </h1>

    
    


  
<div>

 <div className="flex">
    <input
      type="number"
      id="_count"
      name="count"
      min={1}
      onChange={onChangeValue}
      placeholder="Enter Count"
      autoComplete="off"
      required
    />
    <label htmlFor="_count">العدد</label>


      </div>

      <div className="flex">
    
    <DatePicker  selected={date} onChange={(date) =>   
setDate(date)}
dateFormat="yyyy-MM-dd" /> 
" "
<label htmlFor="_date">  التاريخ </label>
   </div>

</div>



   <br></br> 



   <br></br>
    <input type="submit" value="حفظ" />
  </form>

  <h1>حساب عمال الثلج</h1>

  <div className='container1'>
  <table class="table">
  <thead>
  <tr>

  <th scope="col">الحذف</th>
  <th scope="col">التاريخ</th>
  <th scope="col">العدد</th>

  <th scope="col">#</th>
  
  
 
	
     
 
 

			</tr>
      </thead>
<tbody>
  {isproduct.map((item,index)=>(



			<tr>
      <td className='hide' >{c += Number(item.amount) } </td>

     
      <td><p onClick={() => deleteConfirm(item.o_id)} className="btn default-btn"> حذف </p> </td> 
      <td>{item.date}</td> 
    
      <td> {item.amount} KG</td>
     <th scope="row">{i+=1}</th>
     </tr>
    
    				
			

            
))}
</tbody>
 
 <tfoot>
  
  <tr>
  <td colSpan={5}><b>{nf.format(Number(c))}</b></td>
  <th colSpan={5} scope="row">اجمالي العدد المسلم</th>
  </tr>
  
  </tfoot>

</table>
</div>





  </div>
 )}
  </div>
);
};

export default Iceemp;




