import {  useState ,useEffect} from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Header1 from '../header1';
import { useSignIn } from "react-auth-kit";
import {useIsAuthenticated} from 'react-auth-kit';

import Popup from './Popup';




const Signin = () => {

  
  const navigate = useNavigate();
  const signIn = useSignIn()
  const [userInfo, setuserInfo] = useState({
    name: '',
    email: '',
    password: '',
  });
  const isAuthenticated = useIsAuthenticated();

  useEffect( () => {
   
    if(isAuthenticated()){
      navigate(`home`);
    }
    else {
      navigate(`/`);
    }
    


 },[]); 

  



   
   
const [loading, setLoading] = useState(false);
  const onChangeValue = (e) => {
    setuserInfo({
      ...userInfo,
      [e.target.name]:e.target.value
    });
  } 
  // Inserting a new user into the Database.
  const loginUser = async(event) => {
  
    try {


      event.preventDefault();
      event.persist();
      
      localStorage.clear();

      var formData = new FormData();
      
      formData.append("username",userInfo.name); 
      
      formData.append("userpassword",userInfo.password); 
      
     


      axios.post(`https://diamond.future-swift.com/api/signin.php`,formData )
      .then(res => {
       if(res.data.Status==='1'){
        
      
        window.localStorage.setItem('user',0);
        window.localStorage.setItem('week',1);
      
       

        signIn({
          token: res.data.key,
          expiresIn: 1440,
          tokenType: "Bearer",
          authState: { username:res.data.username,key:res.data.key,name:res.data.name},
        });

        navigate(`/home`);
       }else{
        
   
      
        alert('Invalid User');
       }
        
        
       })
    } catch (error) { throw error;} 
   
       
  };







  const [isconfirm, setIsconfirm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

const [v_code, setV_code] = useState(0);
const [pas, setPas] = useState('');


const setdata = (e)=>{
  setV_code(e.target.value);
 
}

const setdata1 = (e)=>{
  setPas(e.target.value);
 
}


const togglePopup = () => {

  setV_code();
  if(isOpen === true){

  }else{
    verfication();
  }
  
  setIsOpen(!isOpen);
 
  }


const togglePopup1 = () => {
 
  setIsconfirm(!isconfirm);
 
  }



  const verfication = async()=>{
 

  
    
     
      
      var formData = new FormData();
      
      formData.append("username",userInfo.name);  
      
      
      
      try {
      
       await axios.post("https://diamond.future-swift.com/api/email1.php",formData)
     
       .then(res => {
       
      })
     
      } catch (ex) {
        console.log(ex);
       
      
      }
    
  };
  
  const alertConfirm = () => {
    if (window.confirm("Are you sure you want confirm ?")) {
      togglePopup();
    }
  }; 


  
const checkverfication = async(v_code) => {
 
 


  
  
  var formData = new FormData();
  
  formData.append("username",userInfo.name); 
 
  formData.append("code",v_code);
  
  
  try {
  
   await axios.post("https://diamond.future-swift.com/api/checkver1.php",formData)
 
   .then(res => {
    if(res.data.Status==='1'){
     
      setIsconfirm(true);
    
      setIsOpen(false);
    }else{

      alert('Invalid code!');
      
    }
    
  })
 
  } catch (ex) {
    console.log(ex);
   
  
  } 
}; 




const changepassword = async(pas) => {
  setLoading(true);
  
  if(pas.length<=8){
    alert('اخل كلمة سر اكبر من 8 ارقام او احرف');
   
  }else{

 

  
  var formData = new FormData();
  
  formData.append("username",userInfo.name); 
 
  formData.append("password",pas);




  
  
  try {
  
   await axios.post("https://diamond.future-swift.com/api/changepassword1.php",formData)
 
   .then(res => {
    if(res.data.Status==='1'){
     
      alert('تم تغيير كلمة السر بنجاح');


      window.location.reload(false);
     

    }else{

      alert('فشل تغيير كلمة السر ');
      
    }
    
  })
 
  } catch (ex) {
    console.log(ex);
   
  
  } finally{
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }  
  }
}; 
  

return (
  <div>
  <Header1/>
  {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>
  <form className="insertForm" onSubmit={loginUser}>
    <h1> Signin </h1>
    <label htmlFor="_name">UserName</label>
    <input
      type="text"
      id="_name"
      name="name"
      onChange={onChangeValue}
      placeholder="Enter name"
      autoComplete="off"
      required
    />
    <br /> <br />
    <label htmlFor="_password">password</label>
    <input
      type="password"
      id="_password"
      name="password"
      onChange={onChangeValue}
      placeholder="Enter password"
      autoComplete="off"
      required
    />
    <br /> <br />
    <input type="submit" value="Signin" />
  </form>
  </div>
 )}



 {userInfo.name === '' ? <button disabled onClick={alertConfirm} className="btn default-btn">لقد نسيت كلمة السر</button>
 :
 <button onClick={alertConfirm} className="btn default-btn">لقد نسيت كلمة السر</button>
 }
 
  
 {isOpen && <Popup
      content={<>
        <h4><b>Verfication Code </b></h4>
        <hr></hr>
        <p>للتأكيد<br></br> 
        يرجى ادخال الكود<br></br> 
        الذي تم ارسالة الى ايميلك </p>
        
        
        <hr></hr>
     
        <label htmlFor="_verication">Verfication Code</label>
        <input onChange={setdata} id='_verication' name='verication' className='confirm' type="text" required/>
        <br></br>
        <button  onClick={() => checkverfication(v_code)}>تأكيد</button>
    
      </>}
      handleClose={togglePopup}
      />}




{isconfirm && <Popup
      content={<>
        <h4><b>Change password</b></h4>
        <hr></hr>
        <p>لتغيير كلمة السر<br></br> 
       يرجى ادخال كلمة السر الجديدة
       <br></br></p>
        
        <hr></hr>

        <label htmlFor="_password">New Password</label>
      <input
        type="password"
        id="_password"
        name="pass"
        onChange={setdata1}
        placeholder="Enter Password"
        autoComplete="off"
        required
      />
      <br /> <br />
        <br></br>
        <button  onClick={() => changepassword(pas)}>تغيير كلمة السر</button>

    
      </>}
      handleClose={togglePopup1}
      />}
 
  </div>
);
};

export default Signin;
