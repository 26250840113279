import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';

import Header from "../Header";
import Nav from 'react-bootstrap/Nav';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';
import {useAuthUser} from 'react-auth-kit'

const Sales = () => {
 


  const auth = useAuthUser();  


  
  useEffect( () => {
   



     allusers();
   
    
    allsales();
  
 
 
  }, []); 
  
  
 
  const allusers = async () => {
    
  
    try {
  
      
      axios.get(`https://diamond.future-swift.com/api/user.php`)
      .then(res => {
       
        setUser(res.data.productlist.productdata);
      })
    } catch (error) { throw error;}    
  
  }


  const tableRef = useRef(null);
 
  const handleSelect = (eventKey) =>{
    localStorage.setItem('user',eventKey);
    setproduct([]);
    allsales();
  } 
  
  const handleSelect1 = (eventKey) =>{
    localStorage.setItem('week',eventKey); 
    setproduct([]);
    allsales();
  } 
  
  const [user, setUser] = useState([]);
  const [isproduct, setproduct] = useState([]);
  
  
const [loading, setLoading] = useState(false);
  const allsales = async () => {
    
    setLoading(true);
    try {
     
      var show = localStorage.getItem('user'); 
      var week = localStorage.getItem('week');  
      var username =  auth().username;
    var key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/sales.php?key=`+key+`&username=`+username+'&show='+show+'&week='+week)
      .then(res => {
        setTsale([]);
        setproduct([]);
        setproduct(res.data.productlist.productdata);
        totalallsales();
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }  
  }

  const [tsale, setTsale] = useState([]);
  const totalallsales = async () => {
    
  
    try {
     
      var show = localStorage.getItem('user'); 
      var week = localStorage.getItem('week');  
      var username =  auth().username;
    var key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/totalallsales.php?key=`+key+`&username=`+username+'&show='+show+'&week='+week)
      .then(res => {
        setTsale([]);
        setTsale(res.data.productlist.productdata[0]);
      })
   
    } catch (error) { throw error;}    
  
  }

  const [enddate, setendDate] = useState(new Date()); 
  const [startDate, setStartDate] = useState(new Date()); 
  
  const allsales1 = async (date,date1) => {
    
  
    try {

      var show = localStorage.getItem('user'); 
      
      var username =  auth().username;
    var key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/filtersale.php?key=`+key+`&username=`+username+'&show='+show+'&date='+date+'&date1='+date1)
      .then(res => {
        setproduct([]);
        setTsale([]);
        setproduct(res.data.productlist.productdata);
        totalallsales_d(date,date1);
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }  
  }
  
  
  
  const filt = () =>{
    setLoading(true);
    setTsale([]);
    
    
    allsales1(format(startDate, 'yyyy-MM-dd'),format(enddate, 'yyyy-MM-dd'));
    
  } 


  const totalallsales_d = async (date,date1) => {
    
  
    try {
     
      var show = localStorage.getItem('user'); 
     
      var username =  auth().username;
      var key = auth().key;
      axios.get(`https://diamond.future-swift.com/api/totalallsales_d.php?key=`+key+`&username=`+username+'&show='+show+'&date='+date+'&date1='+date1)
      .then(res => {
        setTsale([]);
        setTsale(res.data.productlist.productdata[0]);
      })
   
    } catch (error) { throw error;}    
  
  }
  var nf = new Intl.NumberFormat();
  
  var i=0;
  return (
    
    <div>
    <Header/>
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>

        <h1>المبيعات</h1>

    <h2>:عرض عمليات</h2>
    <Nav   fill variant="tabs"  defaultActiveKey={localStorage.getItem('user')} onSelect={handleSelect}>
    <Nav.Item >
          <Nav.Link  eventKey="0" >الكل</Nav.Link>
        </Nav.Item>
    {user.map((user,index)=>( 
      
        <Nav.Item>
          <Nav.Link eventKey={user.id}>{user.name}</Nav.Link>
        </Nav.Item>
        
        
        ))}
       
      </Nav>

       <br />
    
      
      <br /> <br />
    <label htmlFor="_date">من</label>
    <DatePicker selected={startDate} onChange={(date) =>   
setStartDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

    <label htmlFor="_date">الى</label>
    <DatePicker selected={enddate} onChange={(date) =>   
setendDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

  <button onClick={filt}>بحث</button>

    <br /> <br />

    
    
    <div className='container1'>
    <DownloadTableExcel 
                    filename="sales"
                    sheet="sales"
                    currentTableRef={tableRef.current}
                >

                   <button id='excel'> Export excel </button>

                </DownloadTableExcel>
                <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef.current}
      />
    <table class="table" ref={tableRef}>
    <thead>
    <tr>
    <th scope="col">التاريخ</th>
    <th scope="col">المستخدم</th>
    <th scope="col">المتبقي</th>
    <th scope="col">الوارد</th>

    <th scope="col">طريقة الدفع</th>
    <th scope="col">الأجمالي</th>
    <th scope="col">السعر</th> 
    <th scope="col">العدد</th>
    <th scope="col">الصنف</th>
    <th scope="col">اسم العميل</th>
    <th scope="col">رقم العميل</th>
    <th scope="col">#</th>
    
    
   
    
       
   
   
  
        </tr>
        </thead>
  <tbody>
    {isproduct.map((item,index)=>(
      
      
     <tr>
        <td>{item.date}</td> 
        <td>{item.admin_name}</td> 
        <td>{ nf.format(Number(item.total)-Number(item.paid))} </td>
        <td>{ nf.format(item.paid)} </td>
  
        <td>{Number(item.status) === 0 ? "اجل" 
          : "نقد"}</td>
        <td>{ nf.format(item.total)} </td>
        <td>{ nf.format(item.price)} </td>
        <td>{item.amount}</td>
        <td>{item.product}</td> 
        <td>{item.client_name}</td>
        <td>{item.client_id}</td>
        <th scope="row">{i+=1}</th>	
     	</tr>
       	
     
      
       
              
  ))}
  </tbody>
 <tfoot>
<tr colSpan={12}>
<th  colSpan={12} scope="row"><hr></hr> </th>
</tr>


<tr>
<th colSpan={3} scope="row">اجمالي المبيعات</th>
<th colSpan={3}  scope="row">اجمالي النقد</th>
<th colSpan={3} scope="row">اجمالي الاجل</th>
<th colSpan={3} scope="row">اجمالي القوالب</th>
</tr>


<tr>

<td colSpan={3}><b>{ nf.format(Number(tsale.t_total))}</b></td>
<td colSpan={3}><b>{ nf.format(Number(tsale.t_paid))}</b></td>
<td colSpan={3}><b>{ nf.format(Number(tsale.t_total)-Number(tsale.t_paid))}</b></td>
<td colSpan={3}><b>{ nf.format(Number(tsale.t_count))}</b></td>
</tr>
  </tfoot>
  </table>
  </div>
     
  </div>
 )}

    </div>
  )
  }
  

export default Sales;









