import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';

import Header from "../Header";
import Nav from 'react-bootstrap/Nav';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';
import {useAuthUser} from 'react-auth-kit'
const Home = () => {
 
  const auth = useAuthUser();  

useEffect( () => {
  
 

  window.scrollTo(0, 0);
  
  allusers();
  sales();
  
}, []); 

 
const tableRef = useRef(null);

const handleSelect = (eventKey) =>{
  localStorage.setItem('user',eventKey);
  setproduct([]);
  sales();
} 

const [user, setUser] = useState([]);
const [isproduct, setproduct] = useState([]);
const [tsale, setTsale] = useState([]);


const [loading, setLoading] = useState(false);

const sales = async () => {
  
  setLoading(true);
  try {


    
    var show = localStorage.getItem('user');  
    const username =  auth().username;
      const key = auth().key;
    axios.get(`https://diamond.future-swift.com/api/allsales.php?key=`+key+`&username=`+username+'&show='+show)
    .then(res => {
      setproduct([]);
      setTsale([]);
      setproduct(res.data.productlist.productdata);
      totalsales();
  
    })
  } catch (error) { throw error;}  
  finally{
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }  

}
const totalsales = async () => {
  

  try {
    var show = localStorage.getItem('user');  
    const username =  auth().username;
    const key = auth().key;
    
    axios.get(`https://diamond.future-swift.com/api/totalsales.php?key=`+key+`&username=`+username+'&show='+show)
    .then(res => {
      setTsale([]);
      setTsale(res.data.productlist.productdata[0]);
    })
  } catch (error) { throw error;}    

}



const allusers = async () => {
  

  try {

    
    axios.get(`https://diamond.future-swift.com/api/user.php`)
    .then(res => {
     
      setUser(res.data.productlist.productdata);
    })
  } catch (error) { throw error;}    

}




var nf = new Intl.NumberFormat();

var i=0;
return (
  
  <div>
  <Header/>

  {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>
  <h2>عرض مبيعات</h2>
  <Nav   fill variant="tabs"  defaultActiveKey={localStorage.getItem('user')} onSelect={handleSelect}>
  <Nav.Item >
        <Nav.Link  eventKey="0" >الكل</Nav.Link>
      </Nav.Item>
  {user.map((user,index)=>( 
    
      <Nav.Item>
        <Nav.Link eventKey={user.id}>{user.name}</Nav.Link>
      </Nav.Item>
      
      
      ))}
    </Nav>

 

  <h1>المبيعات اليومية</h1>
  
  
  <div className='container1'>
    <DownloadTableExcel 
                    filename="daily-sales"
                    sheet="المبيعات اليومية"
                    currentTableRef={tableRef.current}
                >

                   <button id='excel'> Export excel </button>

                </DownloadTableExcel>
                <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef.current}
      />
  <table className="table" ref={tableRef} >
  <thead>
  <tr>
  <th scope="col">التاريخ</th>
  <th scope="col">المستخدم</th>
  <th scope="col">المتبقي</th>
  <th scope="col">الوارد</th>
  <th scope="col">طريقة الدفع</th>
  <th scope="col">الأجمالي</th>
  <th scope="col">السعر</th> 
  <th scope="col">العدد</th>
  <th scope="col">الصنف</th>
  <th scope="col">اسم العميل</th>
  <th scope="col">رقم العميل</th>
  <th scope="col">#</th>
  
  
 
	
     
 
 

			</tr>
      </thead>


      
<tbody>
  {isproduct.map((item,index)=>(
   
      
      Number(item.total) === 0 ?  
            <tr>
            <td>{item.date}</td> 
            <td>{item.admin_name}</td> 
            <td></td>
    
            <td>{nf.format(item.paid)} </td>
          
            <td>دفع لاجل</td>
            <td colSpan={4} >دفع من مبلغ متبقي عليه</td>
            
            <td>{item.client_name}</td>
            <td>{item.client_id}</td>
           
            <th scope="row">{i+=1}</th>	
            
           </tr>
          :
         <tr>
            <td>{item.date}</td> 
            <td>{item.admin_name}</td> 
            <td>{nf.format(Number(item.total)-Number(item.paid))} </td>
            <td>{nf.format(item.paid)} </td>
           
            <td>{Number(item.status) === 0 ? "اجل" 
              : "نقد"}</td>
            <td>{nf.format(item.total)} </td>
            <td>{nf.format(item.price)} </td>
            <td>{item.amount}</td>
            <td>{item.product}</td> 
            <td>{item.client_name}</td>
            <td>{item.client_id}</td>
            <th scope="row">{i+=1}</th>	
           </tr>

            
))}
</tbody>

<tfoot>
<tr>
<th colSpan={3} scope="row">اجمالي المبيعات</th>
<th colSpan={3}  scope="row">اجمالي النقد</th>
<th colSpan={3} scope="row">اجمالي الاجل</th>
<th colSpan={3} scope="row">اجمالي القوالب</th>
</tr>


<tr>

<td colSpan={3}><b>{nf.format(Number(tsale.t_total))}</b></td>
<td colSpan={3}><b>{nf.format(Number(tsale.t_paid))}</b></td>
<td colSpan={3}><b>{nf.format(Number(tsale.t_total)-Number(tsale.t_paid))}</b></td>
<td colSpan={3}><b>{ nf.format(Number(tsale.t_count))}</b></td>
</tr>


</tfoot>

</table>
</div>
   </div>
 )}

  </div>
)
}


export default Home;









