import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';

import Header from "../Header";
import Nav from 'react-bootstrap/Nav';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';
import {useAuthUser} from 'react-auth-kit'
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
import { Table } from 'react-bootstrap';
const Clientrep = () => {
 
  const auth = useAuthUser();  
 
  
  useEffect( () => {
    
 
  
      allclient();
      
  

 
 
  }, []); 
  


  const [client, setClient] = useState([]);

  const [clientinf, setClientinf] = useState([]);
  

  const [clientr, setClientr] = useState([]);


  const allclient = async () => {
    setLoading(true);

    try {
      const username =  auth().username;
      const key = auth().key;
      
      axios.get(`https://diamond.future-swift.com/api/allclient.php?key=`+key+'&username='+username)
      .then(res => {
       
        setClient(res.data.productlist.productdata);
        setLoading(false);
      })
    } catch (error) { throw error;}    
  
  }


  const handdle_change = (id) => {

    setClientinf([]);
    setTotclient([])
    

    setClientid(id);
    clientinfo(id);
    setClientr([]);

  setSelected(1)


      window.location.href='#report'
  }
  

  const [selected, setSelected] = useState(0);

  const [clientid, setClientid] = useState();




    const clientreminder = async (id) => {
  

      try {
        const username =  auth().username;
        const key = auth().key;
        
        axios.get(`https://diamond.future-swift.com/api/clientreminder.php?key=`+key+'&username='+username+'&id='+id)
        .then(res => {
          setClientr([]);
          setClientr(res.data.productlist.productdata[0]);
        })
      } catch (error) { throw error;}    
    
    }
  
    const [loading, setLoading] = useState(false);
  const clientinfo = async (id) => {

 
    try {
     
 
     
      const username =  auth().username;
      const key = auth().key;
      axios.get(`https://diamond.future-swift.com/api/clientrep.php?key=`+key+`&username=`+username+'&id='+id)
      .then(res => {
        
        setClientinf(res.data.productlist.productdata);
        totalclient(id);

        clientreminder(id);
        
      }
    
    )
   
    } catch (error) { throw error;}    

  }



  const [totclient, setTotclient] = useState([]);
  const totalclient = async (id) => {
    
  
    try {
     
      
      var week = localStorage.getItem('week');  
      const username =  auth().username;
      const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/totalclient.php?key=`+key+`&username=`+username+'&id='+id+'&week='+week)
      .then(res => {
        setTotclient([]); 
        setTotclient(res.data.productlist.productdata[0]);
      })
   
    } catch (error) { throw error;}    
  
  }


  const [enddate, setendDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date()); 
  const clientft = async (date,date1) => {
    
    setLoading(true);

    try {
      
      const username =  auth().username;
      const key = auth().key;
      axios.get(`https://diamond.future-swift.com/api/filterclient.php?key=`+key+`&username=`+username+'&date='+date+'&date1='+date1+'&client_id='+clientid)
      .then(res => {
        
        setClientinf(res.data.productlist.productdata);
        totalclient_d(date,date1);
      })
      setLoading(false);
    } catch (error) { throw error;}    
   
  }
  
  
  
  const filt = () =>{
    setClientinf([]);
    setTotclient([]); 
    clientft(format(startDate, 'yyyy-MM-dd'),format(enddate, 'yyyy-MM-dd'));
   setSelected(0)
    window.location.href='#report'
  } 

 




  const totalclient_d = async (date,date1) => {
    
  
    try {
      
      const username =  auth().username;
      const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/totalclient_d.php?key=`+key+`&username=`+username+'&date='+date+'&date1='+date1+'&client_id='+clientid)
      .then(res => {
        setTotclient([]); 
        setTotclient(res.data.productlist.productdata[0]);
      })
   
    } catch (error) { throw error;}    
  
  }

  var nf = new Intl.NumberFormat();


  const tableRef = useRef(null);
  var total=0;
  var t_paid=0;
  var t_sale=0;
  var i=0;
  return (
    
    <div>
    <Header/>
    <h1>تقرير لحساب/لعميل</h1>
    <br /> <br />
    <br /> <br />




  <br></br>


   
    <br /> <br />
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>
   
        <div dir='rtl'>
  

        <label  dir='rtl' htmlFor="_client">الحساب</label> 


  
      
<DatalistInput

style={{color:'#000',}}
placeholder="العميل"
autoSave='off'
onSelect={(item) => handdle_change(item.id)}
items={  client.map(option => (

  { id: option.value, value:option.label }
  
  ))

}
/>

    <br /> <br />
    <label  dir='rtl' htmlFor="_date">من</label>
    <DatePicker selected={startDate} onChange={(date) =>   
setStartDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

    <label  dir='rtl' htmlFor="_date">الى</label>
    <DatePicker  selected={enddate} onChange={(date) =>   
setendDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

  <button onClick={filt}>بحث</button>

<br /> <br />

</div> 
    
    <div className='container1' id='report'>
    <DownloadTableExcel 
                    filename="client"
                    sheet="العميل"
                    currentTableRef={tableRef.current}
                >

                   <button id='excel'> Export excel </button>

                </DownloadTableExcel>
                <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef.current}
      />

   
    <table class="table" ref={tableRef} >
  

    <caption style={{captionSide:'top'}}>
    <div className='flex' style={{width:'100%',zIndex:1,placeItems:'center',marginLeft:'0.5%',marginRight:'0.5%' ,backgroundColor:'ButtonHighlight'}}  dir='rtl'>




<div  style={{width:'33%'}}>
<p style={{color:'black',fontSize:'20px',textAlign:'center'}}>كشف حساب </p>

<p style={{color:'black',fontSize:'20px',textAlign:'center'}}>ل{clientinf.length > 0 ? clientinf[0].client_name :''}</p>


</div>

<div className='flex' style={{width:'33%'}}>
<img  style={{margin:'auto'}} src='https://diamond.future-swift.com/diamond-logo.png' />
</div> 

<div  style={{width:'33%',}}>
<p style={{color:'black',fontSize:'15px',textAlign:'center'}}>{format(startDate, 'yyyy-MM-dd') === format(enddate, 'yyyy-MM-dd') ? 

selected === 1 ?
'كل الفترة'
:
`    ${format(startDate, 'yyyy-MM-dd')}`

:

selected === 1 ?
'كل الفترة'
:
`   ${format(startDate, 'yyyy-MM-dd')} / ${format(enddate, 'yyyy-MM-dd')}`
}</p>
</div>

</div>
<hr></hr>
  </caption>
<thead>
    <tr>
    <th scope="col">ملاحظة</th>
    <th scope="col">التاريخ</th>
    <th scope="col">رقم السند</th>
    <th scope="col">المستخدم</th>
    <th scope="col">المتبقي</th>
    <th scope="col">الوارد</th>
   
    <th scope="col">طريقة الدفع</th>
    <th scope="col">الأجمالي</th>
    <th scope="col">السعر</th> 
    <th scope="col">العدد</th>
    <th scope="col">الصنف</th>
    <th scope="col">اسم العميل</th>
    <th scope="col">رقم العميل</th>
    <th scope="col">#</th>
    
  
        </tr>
        </thead>
        <tr ><th colSpan={15} ><hr></hr></th></tr>


  <tbody>
    {clientinf.map((item,index)=>(
      
      
  Number(item.total) === 0 ?  
        <tr>
        <td>{item.note}</td> 
        <td>{item.date}</td> 
        <td>{item.receipt}</td> 
        <td>{item.admin_name}</td> 
        <td></td>

        <td>{nf.format(item.paid)} </td>
      
        <td>دفع لاجل</td>
        <td colSpan={4} >دفع من مبلغ متبقي عليه</td>
        
        <td>{item.client_name}</td>
        <td>{item.client_id}</td>
       
        <th scope="row">{i+=1}</th>	
        <td className='hide'>{nf.format(t_sale+=Number(item.total) )} </td>
        <td className='hide'>{nf.format(t_paid+=Number(item.paid) )} </td>
       </tr>
      :
     <tr>
     <td>{item.note}</td> 
        <td>{item.date}</td> 
        <td>{item.receipt}</td> 
        <td>{item.admin_name}</td> 
        <td>{nf.format(Number(item.total)-Number(item.paid))} </td>
        <td>{nf.format(item.paid)} </td>
       
        <td>{Number(item.status) === 0 ? "اجل" 
          : "نقد"}</td>
        <td>{nf.format(item.total)} </td>
        <td>{nf.format(item.price)} </td>
        <td>{item.amount}</td>
        <td>{item.product}</td> 
        <td>{item.client_name}</td>
        <td>{item.client_id}</td>
        <th scope="row">{i+=1}</th>	

        <td className='hide'>{nf.format(t_sale+=Number(item.total) )} </td>
        <td className='hide'>{nf.format(t_paid+=Number(item.paid) )} </td>
     	</tr>
       	
     
      
       
              
  ))}
  </tbody>
  <tfoot>
<tr colSpan={15}>
<th  colSpan={15} scope="row"><hr></hr> </th>
</tr>


<tr>
<th  colSpan={3} scope="row">اجمالي المبيعات</th>
<th colSpan={3}  scope="row">اجمالي المسلم</th>
<th colSpan={3} scope="row">اجمالي الاجل</th>

{ format(startDate, 'yyyy-MM-dd') === format(enddate, 'yyyy-MM-dd') ? 

selected === 1 ?

<th dir='rtl' colSpan={3} scope="row">المبلغ المتبقي عليه</th>
:
 <th dir='rtl' colSpan={3} scope="row">المبلغ المتبقي عليه في تاريخ {format(startDate, 'yyyy-MM-dd')}</th>

:

selected === 1 ?
<th dir='rtl' colSpan={3} scope="row">المبلغ المتبقي عليه</th>
:
<th dir='rtl' colSpan={3} scope="row">المبلغ المتبقي عليه من {format(startDate, 'yyyy-MM-dd')} / {format(enddate, 'yyyy-MM-dd')}</th>
}
<th colSpan={3} scope="row">المبلغ المتبقي عليه ككل</th>
</tr>


<tr>

<td colSpan={3}><b>{nf.format(Number(t_sale))}</b></td>
<td colSpan={3}><b>{nf.format(Number(t_paid))}</b></td>
<td colSpan={3}><b>{nf.format(Number(totclient.t_total)-Number(totclient.t_paid))}</b></td>
<td colSpan={3}><b>{nf.format(Number(t_sale)-Number(t_paid))}</b></td>
<td colSpan={3}><b>{nf.format(Number(clientr.t)-Number(clientr.p))}</b></td>
</tr>
  
</tfoot>
  
  </table>
  </div>
     
  </div>
 )}

    </div>
  )
  }
  

export default Clientrep ;









