import React,{useState,useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {useAuthUser} from 'react-auth-kit'

const Editclient = (props) => {

  const navigate = useNavigate();
 
  const auth = useAuthUser();  


 
  const [saleinfo, setSaleinfo] = useState({
    name: props.list.name,
    phone: props.list.phone,
    
  });
  const onChangeValue = (e) => {
    setSaleinfo({
      ...saleinfo,
      [e.target.name]:e.target.value
    });
  } 
  // Inserting a new user into the Database.
  const updatesale = async(event) => {
    try {
      event.preventDefault();
      event.persist();
    
      var formData = new FormData();
      formData.append("client_id",props.list.client_id); 
      formData.append("name",saleinfo.name); 
      formData.append("phone",saleinfo.phone); 
       
      formData.append("username",auth().username); 
      formData.append("key",auth().key); 

      axios.post(`https://diamond.future-swift.com/api/editclient.php`, formData )
      .then(res => {
        console.log(res.data);
        navigate(`/myclient`);
        return;
       })
    } catch (error) { throw error;}    
  };


 

return (
  <dev>  
 
 <form className="insertForm" onSubmit={updatesale}>
    <h1> تعديل الحساب/العميل </h1>

    <br /> <br />
    <label htmlFor="_name">أسم الحساب</label>
    <input
      type="text"
      id="_name"
      name="name"
      value={saleinfo.name}
      onChange={onChangeValue}
      placeholder="Enter name"
      autoComplete="off"
      required
    />

    <br /> <br />
    <label htmlFor="_phone">رقم الهاتف</label>
    <input
      type="phone"
      id="_phone"
      name="phone"
      value={saleinfo.phone}
      onChange={onChangeValue}
      placeholder="Enter Phone"
      autoComplete="off"
      required
    />
     <br /> <br />
    <input type="submit" value="حفظ" />
  </form>
  </dev>
);
};

export default Editclient;