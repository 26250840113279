import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';

import Header from "../Header";
import Nav from 'react-bootstrap/Nav';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ReactToPrint from 'react-to-print';
import {useAuthUser} from 'react-auth-kit'

const Inrep = () => {
 
  const auth = useAuthUser();  
  
  useEffect( () => {
    
   
  
      allclient();
      
    window.scrollTo(0, 0);

 
 
  }, []); 
  

  const [client, setClient] = useState([]);

  const [clientinf, setClientinf] = useState([]);
  

  const [clientr, setClientr] = useState([]);


  const allclient = async () => {
  

    try {
      const username =  auth().username;
      const key = auth().key;
      
      axios.get(`https://diamond.future-swift.com/api/allclient.php?key=`+key+'&username='+username)
      .then(res => {
       
        setClient(res.data.productlist.productdata);
      })
    } catch (error) { throw error;}    
  
  }



  const handleSelect1 = (eventKey) =>{
    localStorage.setItem('week',eventKey);
    setClientinf([]);
    setTotclient([]);
    clientinfo(clientid);
    totalclient(clientid);
  } 


  const [clientid, setClientid] = useState();

  const onChangeValue = (e) => {
    setClientinf([]);
    setTotclient([]);
    setClientid(e.target.value);
    clientinfo(e.target.value);
    setClientr([]);
    }


   
  
    const [loading, setLoading] = useState(false);
  const clientinfo = async (id) => {
    
    setLoading(true);
    try {
     
     
      var week = localStorage.getItem('week');  
      const username =  auth().username;
      const key = auth().key;
      axios.get(`https://diamond.future-swift.com/api/inrep.php?key=`+key+`&username=`+username+'&id='+id+'&week='+week)
      .then(res => {
        
        setClientinf(res.data.productlist.productdata);
        totalclient(id);

      
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }  
  }



  const [totclient, setTotclient] = useState([]);
  const totalclient = async (id) => {
    
  
    try {
     
      
      var week = localStorage.getItem('week');  
      const username =  auth().username;
      const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/totalin.php?key=`+key+`&username=`+username+'&id='+id+'&week='+week)
      .then(res => {
        setTotclient([]); 
        setTotclient(res.data.productlist.productdata[0]);
      })
   
    } catch (error) { throw error;}    
  
  }


  const [enddate, setendDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date()); 
  const clientft = async (date,date1) => {
    
    setLoading(true);

    try {
      
      const username =  auth().username;
      const key = auth().key;
      axios.get(`https://diamond.future-swift.com/api/filterin.php?key=`+key+`&username=`+username+'&date='+date+'&date1='+date1+'&client_id='+clientid)
      .then(res => {
        
        setClientinf(res.data.productlist.productdata);
        totalclient_d(date,date1);
      })
   
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }  
  }
  
  
  
  const filt = () =>{
    setClientinf([]);
    setTotclient([]); 
    clientft(format(startDate, 'yyyy-MM-dd'),format(enddate, 'yyyy-MM-dd'));
    
  } 

 




  const totalclient_d = async (date,date1) => {
    
  
    try {
      
      const username =  auth().username;
      const key = auth().key;
  
      axios.get(`https://diamond.future-swift.com/api/totalin_d.php?key=`+key+`&username=`+username+'&date='+date+'&date1='+date1+'&client_id='+clientid)
      .then(res => {
        setTotclient([]); 
        setTotclient(res.data.productlist.productdata[0]);
      })
   
    } catch (error) { throw error;}    
  
  }

  var nf = new Intl.NumberFormat();


  const tableRef = useRef(null);
  
  var i=0;
  return (
    
    <div>
    <Header/>
    <h1>تقرير ايراد لحساب/لعميل</h1>
    <br /> <br />
    <br /> <br />

      <label htmlFor="_client">الحساب</label> 
  <br></br>
    <select id='_client' name='client'  onChange={onChangeValue} required>
    <option value='' selected> Choose... </option>
        {client.map(option => (
          
          <option key={option.value} value={option.value}>

            {option.label }

          </option>
        ))}
      </select>
    
   
    <br /> <br />
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div>
   
   
   
    <br /> <br />


    <br /> <br />
    <label htmlFor="_date">من</label>
    <DatePicker selected={startDate} onChange={(date) =>   
setStartDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

    <label htmlFor="_date">الى</label>
    <DatePicker selected={enddate} onChange={(date) =>   
setendDate(date)}
dateFormat="yyyy-MM-dd" /> 
   
    <br /> <br />

  <button onClick={filt}>بحث</button>

<br /> <br />
   
    
    
    <div className='container1'>
    <DownloadTableExcel 
                    filename="client"
                    sheet="العميل"
                    currentTableRef={tableRef.current}
                >

                   <button id='excel'> Export excel </button>

                </DownloadTableExcel>
                <ReactToPrint
        trigger={() => <button id='pdf'> Print pdf </button>}
        content={() => tableRef.current}
      />
      <table class="table" ref={tableRef}>
    <thead>
    <tr>
    <th scope="col">التاريخ</th>
    <th scope="col">المستخدم</th>
    <th scope="col">الوارد</th>
   
    <th scope="col">لحساب</th>
    <th scope="col">رقم الحساب</th>
    <th scope="col">#</th>
    
    
   
    
       
   
   
  
        </tr>
        </thead>
  <tbody>
    {clientinf.map((item,index)=>(
      

        <tr>
        <td>{item.date}</td> 
        <td>{item.admin_name}</td> 
        <td>{ nf.format(item.paid)} </td>
  
        <td>{item.client_name}</td>
        <td>{item.client_id}</td>
       
        <th scope="row">{i+=1}</th>	
        
       </tr>
     
              
  ))}
  </tbody>
  
 <tfoot>
  
  <tr>
  <th  scope="row">اجمالي الايرادات</th>
  <td colSpan={9}><b>{ nf.format(Number(totclient.t_paid))}</b></td>
  </tr>
  </tfoot>
  </table>
  </div>
     
  </div>
 )}

    </div>
  )
  }
  

export default Inrep ;









