import { Route, Routes } from "react-router-dom";
import "./App.css";


import Signin from "./page/Signin";

import Home from "./page/Home";
import Mysales from "./page/Mysales";
import Addsale from "./page/Addsale";

import Myincome from "./page/Myincome";
import Addincome from "./page/Addincome";
import Addclient from "./page/Addclient";
import Myclient from "./page/Myclient";


import Edit from "./page/Edit";
import Edit1 from "./page/Edit1";
import Edit2 from "./page/Edit2";
import Edit3 from "./page/Edit3";
import Edit4 from "./page/Edit4";

import Profile from "./page/Profile";

import Myoutcome from "./page/Myoutcome";
import Addoutcome from "./page/Addoutcome";
import Addemployee from "./page/Addemlpoyee";
import Myemployee from "./page/Myemployee";


import Sales from "./page/Sales";
import Income from "./page/Income";
import Outcome from "./page/Outcome";

import Clientsreport from "./page/Clientsreport";
import Clientrep from "./page/Clientrep";
import Outrep from "./page/Outrep";
import Inrep from "./page/Inrep";
import Palayoffs from "./page/Playoffs";

import { RequireAuth } from "react-auth-kit";
import Iceemp from "./page/Iceemp";
import Iceemprep from "./page/Iceemprep";
import Conc from "./page/Conc";
function App() {

  


  return (
    <div className='app'>
     
      <Routes>
       <Route path="/" element={<Signin />} />
    
      
       <Route path="/home" element={  <RequireAuth loginPath="/"> <Home />  </RequireAuth>} />
       <Route path="/myprofile" element={ <RequireAuth loginPath="/"> <Profile /> </RequireAuth>} />
       
      
        <Route path="/mysale" element={<RequireAuth loginPath="/"><Mysales /></RequireAuth>} />
        <Route path="/insert" element={<RequireAuth loginPath="/"><Addsale /></RequireAuth>} />
        <Route path="mysale/edit/:ids" element={<RequireAuth loginPath="/"><Edit /></RequireAuth>} />
        <Route path="insert/edit/:ids" element={<RequireAuth loginPath="/"><Edit /></RequireAuth>} />

        <Route path="/myincome" element={<RequireAuth loginPath="/"><Myincome /></RequireAuth>} />
        <Route path="/addincome" element={<RequireAuth loginPath="/"><Addincome /></RequireAuth>} />
        <Route path="myincome/edit/:ids" element={<RequireAuth loginPath="/"><Edit1 /></RequireAuth>} />
        <Route path="addincome/edit/:ids" element={<RequireAuth loginPath="/"><Edit1 /></RequireAuth>} />
        <Route path="/addclient" element={<RequireAuth loginPath="/"><Addclient /></RequireAuth>} />
        <Route path="/myclient" element={<RequireAuth loginPath="/"><Myclient /></RequireAuth>} />
        <Route path="myclient/edit/:ids" element={<RequireAuth loginPath="/"><Edit4 /></RequireAuth>} />
        <Route path="/addclient/edit/:ids" element={<RequireAuth loginPath="/"><Edit4 /></RequireAuth>} />

        <Route path="/myoutcome" element={<RequireAuth loginPath="/"><Myoutcome /></RequireAuth>} />
        <Route path="/addoutcome" element={<RequireAuth loginPath="/"><Addoutcome /></RequireAuth>} />
        <Route path="addoutcome/edit/:ids" element={<RequireAuth loginPath="/"><Edit2 /></RequireAuth>} />
        <Route path="myoutcome/edit/:ids" element={<RequireAuth loginPath="/"><Edit2 /></RequireAuth>} />
        <Route path="/addemployee" element={<RequireAuth loginPath="/"><Addemployee /></RequireAuth>} />
        <Route path="/myemployee" element={<RequireAuth loginPath="/"><Myemployee /></RequireAuth>} />
        <Route path="myemployee/edit/:ids" element={<RequireAuth loginPath="/"><Edit3 /></RequireAuth>} />
       
       
        <Route path="/sales" element={<RequireAuth loginPath="/"><Sales /></RequireAuth>} />
        <Route path="/income" element={<RequireAuth loginPath="/"><Income /></RequireAuth>} />
        <Route path="/outcome" element={<RequireAuth loginPath="/"><Outcome /></RequireAuth>} />
        <Route path="/clients" element={<RequireAuth loginPath="/"><Clientsreport /></RequireAuth>} />
        <Route path="/client" element={<RequireAuth loginPath="/"><Clientrep /></RequireAuth>} />

        <Route path="/outrep" element={<RequireAuth loginPath="/"><Outrep /></RequireAuth>} />
        <Route path="/inrep" element={<RequireAuth loginPath="/"><Inrep /></RequireAuth>} />

        <Route path="/playoffs" element={<RequireAuth loginPath="/"><Palayoffs /></RequireAuth>} />
        <Route path="/conc" element={<RequireAuth loginPath="/"><Conc /></RequireAuth>} />

        <Route path="/iceemp" element={<RequireAuth loginPath="/"><Iceemp /></RequireAuth>} />
        <Route path="/iceemprep" element={<RequireAuth loginPath="/"><Iceemprep /></RequireAuth>} />
      </Routes>
    </div>
  );
}
export default App; 