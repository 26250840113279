import { useEffect, useState } from "react";
import { Link, Navigate, } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import axios from 'axios';
import { useSignOut } from "react-auth-kit";
import {useAuthUser} from 'react-auth-kit'

const Header=()=>{
 
 const logout = useSignOut();

 const auth = useAuthUser();  
   

useEffect(()=>{

  
    user1();
},[])




const logOut =()=>{
  logout();
    localStorage.removeItem('key');
    localStorage.clear();
    
    Navigate('/');

}




const [userInfo, setuserInfo] = useState({});

const user1 = async () => {
 

  try {
    var username =  auth().username;
    var key = auth().key;
      
    axios.get(`https://diamond.future-swift.com/api/userinfo.php?key=`+key+`&username=`+username)
    .then(res => {
    
      console.log(res.data.userinfo.userdata);
      setuserInfo(res.data.userinfo.userdata[0]);
      
    })
   
  } catch (error) { throw error;}    
}
        
        return(

<div>

          <Navbar  fixed="top"  bg="light" expand="lg">
          <Container>
          <a className="imglink" href="/myprofile">
              <label className="username"><img className="icon" src={'https://diamond.future-swift.com/api/profile/'+userInfo.picture} alt="" /> <b>{auth().name}</b></label>
              </a>
              <Navbar.Brand className="title" href="/home"><b>Diamond</b></Navbar.Brand>
       
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav variant="tabs" >
            
          
                  
            <NavDropdown id="nav-dropdown-dark-example" title="الملف الشخصي" menuVariant="dark">
             <NavDropdown.Divider />
              <NavDropdown.Item href="/myprofile">حسابي</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/sales">المبيعات</NavDropdown.Item>
              <NavDropdown.Item href="/income">صندوق الأيرادات</NavDropdown.Item>
              <NavDropdown.Item href="/outcome">صندوق المصروفات</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/insert">اضافة بيع جديد</NavDropdown.Item>
              <NavDropdown.Item href="/addincome">اضافة ايراد جديد</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/addoutcome">اضافة صرف جديد</NavDropdown.Item>         
              <NavDropdown.Divider />
              <NavDropdown.Item href="/myclient">الحسابات/العملاء</NavDropdown.Item>
              <NavDropdown.Item href="/myemployee">الحسابات/الموظفين</NavDropdown.Item>
            </NavDropdown>



             <NavDropdown id="nav-dropdown-dark-example" title="المبيعات" menuVariant="dark">
             
             <NavDropdown.Item href="/mysale">مبيعاتي</NavDropdown.Item>
              <NavDropdown.Item href="/insert">اضافة بيع جديد</NavDropdown.Item>
              <NavDropdown.Item href="/myincome">ايراداتي</NavDropdown.Item>
              <NavDropdown.Item href="/addincome">اضافة ايراد جديد</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/myclient">الحسابات/العملاء</NavDropdown.Item>
              <NavDropdown.Item href="/addclient">اضافة حساب/عميل جديد</NavDropdown.Item>
            </NavDropdown>      
          
          
         

            <NavDropdown id="nav-dropdown-dark-example" title="المصروفات" menuVariant="dark">
            <NavDropdown.Item href="/myoutcome">مصروفاتي</NavDropdown.Item>
              <NavDropdown.Item href="/addoutcome">اضافة صرف جديد</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/myemployee">الحسابات/الموظفين</NavDropdown.Item>
              <NavDropdown.Item href="/addemployee">اضافة حساب/موظف جديد</NavDropdown.Item>
            </NavDropdown>    
           
            

                 
            <NavDropdown id="nav-dropdown-dark-example" title="التقارير" menuVariant="dark">
              <NavDropdown.Item href="/sales">المبيعات</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/income">صندوق الأيرادات</NavDropdown.Item>
              <NavDropdown.Item href="/outcome">صندوق المصروفات</NavDropdown.Item>
               <NavDropdown.Divider />
               <NavDropdown.Item href="/inrep">تقرير ايراد حساب/عميل محدد</NavDropdown.Item>
               <NavDropdown.Item href="/outrep">تقرير صرف حساب/موظف محدد</NavDropdown.Item>
               <NavDropdown.Divider />
              <NavDropdown.Item href="/clients">تقرير لجميع الحسابات/العملاء</NavDropdown.Item>
              <NavDropdown.Item href="/client">تقرير لحساب/لعميل محدد</NavDropdown.Item>
          
              <NavDropdown.Divider />
              <NavDropdown.Item href="">خلاصة الفترة</NavDropdown.Item>
            </NavDropdown>   
            
            
            <NavDropdown id="nav-dropdown-dark-example" title="المسلم من المصنع" menuVariant="dark">
            <NavDropdown.Divider />
            <NavDropdown.Item href="/iceemp">ادارة الكمية المسلمة</NavDropdown.Item>
             <NavDropdown.Item href="/iceemprep">تقارير الكمية المسلمة</NavDropdown.Item>
              <NavDropdown.Divider />
             
            </NavDropdown>      
            
            
            
            
            
            <Link  class="logout" onClick={logOut} ><b>LogOut</b></Link>
            </Nav>
            </Navbar.Collapse>
                
                
                
               
              
                
            </Container>
    </Navbar>

    </div>


        ); 
       
       
    }
    


export default Header;